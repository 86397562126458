import { Typography, useTheme } from '@mui/material';
import { Layer, Popup, Source } from 'react-map-gl';

import { PopupContent } from '@/components/PopupContent';
import { PopupDatePeriod } from '@/components/popup/PopupDatePeriod';
import { PopupTitle } from '@/components/popup/PopupTitle';
import { RoutePropertyService } from '@/modules/RoutePropertyService';
import { StrategyFeature } from '@/types/StrategyFeature';
import { StrategyLayerType } from '@/types/StrategyLayerType';

type StrategyPopupProps = {
  feature?: StrategyFeature;
  onClose: () => void;
};

export function StrategyPopup({ feature, onClose }: StrategyPopupProps) {
  const theme = useTheme();

  if (!feature) {
    return null;
  }

  const [longitude, latitude] = feature.geometry.coordinates;

  const description = feature.properties.comments?.replace('{}', '').replace('{"', '').replace('"}', '');

  const routeAvoidMatched =
    feature.properties.route_avoid_matched && JSON.parse(feature.properties.route_avoid_matched);
  const routeMatched = feature.properties.route_matched && JSON.parse(feature.properties.route_matched);

  return (
    <>
      <Popup
        key={feature.properties.id}
        longitude={longitude}
        latitude={latitude}
        onClose={onClose}
        offset={18}
        maxWidth="min(480px, 75vw)"
        closeButton={false}
      >
        <PopupContent onClose={onClose}>
          <PopupTitle>{feature.properties.type}</PopupTitle>
          <PopupDatePeriod feature={feature} />

          {description && <Typography>{description}</Typography>}
        </PopupContent>
      </Popup>

      <Source type="geojson" data={RoutePropertyService.getAlternativeStrategyGeoJson(routeAvoidMatched, routeMatched)}>
        <Layer
          beforeId={StrategyLayerType.ALTERNATIVE_ROUTING}
          type="line"
          layout={{
            'line-cap': 'round',
          }}
          paint={{
            'line-color': 'white',
            'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 6, 14, 8, 18, 12],
          }}
        />
        <Layer
          beforeId={StrategyLayerType.ALTERNATIVE_ROUTING}
          type="line"
          layout={{
            'line-cap': 'round',
          }}
          paint={{
            'line-color': [
              'case',
              ['==', ['get', 'type'], 'strategy-avoid'],
              theme.palette.error.main,
              ['==', ['get', 'type'], 'traffic-report'],
              theme.palette.error.main,
              theme.palette.success.main,
            ],
            'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 2, 14, 4, 18, 8],
          }}
        />
      </Source>
    </>
  );
}
