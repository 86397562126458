import { Navigate, Route } from 'react-router-dom';

import { AboutPage } from '@/pages/AboutPage';
import { DataPrivacyPage } from '@/pages/DataPrivacyPage';
import { ImprintPage } from '@/pages/ImprintPage';
import { RoutingPage } from '@/pages/RoutingPage';
import { SentryRoutes } from '@/setup/sentry';

export function App() {
  return (
    <SentryRoutes>
      <Route path="/" element={<RoutingPage />} />
      <Route path="/ueber-das-projekt" element={<AboutPage />} />
      <Route path="/impressum" element={<ImprintPage />} />
      <Route path="/datenschutz" element={<DataPrivacyPage />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </SentryRoutes>
  );
}
