import { Box, Container, styled, css } from '@mui/material';
import { ReactNode } from 'react';

import { Header } from '@/components/Header';
import { MobileNavigation } from '@/components/MobileNavigation';

const StyledRoot = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.primary.light};
    color: ${theme.palette.common.white};
    height: 100vh;
    overflow: auto;
  `,
);

type PageWrapperProps = {
  children: ReactNode;
};

export function PageWrapper({ children }: PageWrapperProps) {
  return (
    <StyledRoot>
      <Header />
      <MobileNavigation />
      <Container sx={{ paddingBottom: 4, paddingTop: 4 }}>{children}</Container>
    </StyledRoot>
  );
}
