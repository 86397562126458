import { Layer, Source } from 'react-map-gl';

import { BEFORE_LABEL_ID } from '@/modules/BeforeLabelsId';
import { GeoServer } from '@/modules/GeoServer';

export function LevelOfServiceLayer() {
  return (
    <>
      {/* <Source */}
      {/*  id={TrafficReportLayerType.LEVEL_OF_SERVICE} */}
      {/*  type="vector" */}
      {/*  tiles={[GeoServer.wmts({ layer: 'vis_live_filtered' })]} */}
      {/*  bounds={[7.726052171733736, 49.391684399151906, 10.219795599662884, 51.65640759482277]} */}
      {/* > */}
      {/*  <Layer */}
      {/*    key={TrafficReportLayerType.LEVEL_OF_SERVICE} */}
      {/*    id={TrafficReportLayerType.LEVEL_OF_SERVICE} */}
      {/*    type="line" */}
      {/*    source-layer="vis_live_filtered" */}
      {/*    beforeId={BEFORE_LABEL_ID} */}
      {/*    layout={{ */}
      {/*      'line-cap': 'round', */}
      {/*      'line-join': 'round', */}
      {/*    }} */}
      {/*    paint={{ */}
      {/*      'line-color': [ */}
      {/*        'match', */}
      {/*        ['get', 'los'], */}
      {/*        ...LevelOfServiceColors.map(({ value, color }) => [value, color]).flat(), */}
      {/*        'transparent', */}
      {/*      ], */}
      {/*      'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 2, 14, 4, 18, 8], */}
      {/*    }} */}
      {/*  /> */}
      {/* </Source> */}
      <Source type="raster" tiles={[GeoServer.wms({ layers: 'vis_live_bigroads' })]} tileSize={256}>
        <Layer type="raster" beforeId={BEFORE_LABEL_ID} paint={{ 'raster-opacity': 0.75 }} maxzoom={11} />
      </Source>
      <Source type="raster" tiles={[GeoServer.wms({ layers: 'vis_live_filtered' })]} tileSize={256}>
        <Layer type="raster" beforeId={BEFORE_LABEL_ID} paint={{ 'raster-opacity': 0.75 }} minzoom={10} />
      </Source>
    </>
  );
}
