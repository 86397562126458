import { useQuery } from '@tanstack/react-query';
import { Layer, Source } from 'react-map-gl';

import AbnormalTrafficIcon from '@/assets/icons/map/traffic-report/abnormal-traffic.svg?url';
import AccidentIcon from '@/assets/icons/map/traffic-report/accident.svg?url';
import ConditionsIcon from '@/assets/icons/map/traffic-report/conditions.svg?url';
import ConstructionWorksIcon from '@/assets/icons/map/traffic-report/construction-works.svg?url';
import EnvironmentalObstructionIcon from '@/assets/icons/map/traffic-report/environmental-obstruction.svg?url';
import MaintenanceWorksIcon from '@/assets/icons/map/traffic-report/maintenance-works.svg?url';
import PublicEventIcon from '@/assets/icons/map/traffic-report/public-event.svg?url';
import ReroutingManagementIcon from '@/assets/icons/map/traffic-report/rerouting-management.svg?url';
import RoadOrCarriagewayOrLaneManagementIcon from '@/assets/icons/map/traffic-report/road-or-carriageway-or-lane-management.svg?url';
import WarningIcon from '@/assets/icons/map/traffic-report/warning.svg?url';
import { useMapImages } from '@/hooks/useMapImages';
import { FeatureCollectionToCenterOfMass } from '@/modules/FeatureCollectionToCenterOfMass';
import { GeoServer } from '@/modules/GeoServer';
import { TrafficReportLayerType } from '@/types/TrafficReportLayerType';
import { TrafficReportType } from '@/types/TrafficReportType';

const ICON_DIMENSIONS = { width: 36, height: 36 };

export function TrafficReportLayer() {
  useMapImages({
    images: [
      { name: TrafficReportType.ABNORMAL_TRAFFIC, url: AbnormalTrafficIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.ACCIDENT, url: AccidentIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.CONDITIONS, url: ConditionsIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.CONSTRUCTION_WORKS, url: ConstructionWorksIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.ENVIRONMENTAL_OBSTRUCTION, url: EnvironmentalObstructionIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.MAINTENANCE_WORKS, url: MaintenanceWorksIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.PUBLIC_EVENT, url: PublicEventIcon, ...ICON_DIMENSIONS },
      { name: TrafficReportType.REROUTING_MANAGEMENT, url: ReroutingManagementIcon, ...ICON_DIMENSIONS },
      {
        name: TrafficReportType.ROAD_OR_CARRIAGEWAY_OR_LANE_MANAGEMENT,
        url: RoadOrCarriagewayOrLaneManagementIcon,
        ...ICON_DIMENSIONS,
      },
      { name: TrafficReportType.WARNING, url: WarningIcon, ...ICON_DIMENSIONS },
    ],
  });

  const { data: trafficReport } = useQuery({
    queryKey: [TrafficReportLayerType.TRAFFIC_REPORT],
    queryFn: () => FeatureCollectionToCenterOfMass.fetch(GeoServer.wfs({ typeName: 'traffic_obstruction_geoms' })),
  });

  return (
    <Source
      id={TrafficReportLayerType.TRAFFIC_REPORT}
      type="geojson"
      data={trafficReport || { type: 'FeatureCollection', features: [] }}
    >
      <Layer
        key={TrafficReportLayerType.TRAFFIC_REPORT}
        id={TrafficReportLayerType.TRAFFIC_REPORT}
        type="symbol"
        layout={{
          'icon-allow-overlap': true,
          'icon-size': 0.5,
          'icon-image': [
            'match',
            ['get', 'type'],
            ...Object.values(TrafficReportType)
              .filter((value) => value !== TrafficReportType.WARNING)
              .map((value) => [value, value])
              .flat(),
            // Fallback
            TrafficReportType.WARNING,
          ],
        }}
      />
    </Source>
  );
}
