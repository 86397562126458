import { Box, Button, css, Grid, styled } from '@mui/material';

import { ModeIcon } from '@/components/routing/ModeIcon';
import { useRoutes } from '@/hooks/useRoutes';
import { RoutePropertyService } from '@/modules/RoutePropertyService';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { Mode } from '@/types/Mode';
import { RouteLocationType } from '@/types/RouteLocationType';
import { transientOptions } from '@/utils/transient-options';

const StyledCircle = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    background-color: ${theme.palette.error.main};
    width: ${theme.spacing(1.5)};
    height: ${theme.spacing(1.5)};
    top: 0;
    right: ${theme.spacing(-0.5)};
    border: none;
    border-radius: 6px;
  `,
);

const StyledButton = styled(
  Button,
  transientOptions,
)<{ $activeButton?: boolean }>(
  ({ theme, $activeButton }) => css`
    display: flex;
    padding: ${theme.spacing(1)};
    width: ${theme.spacing(14)};
    height: ${theme.spacing(5)};
    background-color: ${$activeButton && theme.palette.common.white};
    color: ${$activeButton && theme.palette.primary.light};

    &.MuiButtonBase-root:hover {
      background-color: ${$activeButton && theme.palette.common.white};
    }

    & .MuiButton-startIcon {
      margin-right: 2px;
    }

    &.Mui-disabled {
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.primary.light};
      opacity: 0.5;
    }
  `,
);

const StyledBox = styled(Box)(
  () => css`
    width: 4px;
    height: 4px;
    background: rgba(255, 255, 255, 0.5);
    display: inline-block;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    margin: 0 1.5px;
    position: relative;
    animation: jump 1s infinite;

    &.dot-1 {
      -webkit-animation-delay: 100ms;
      animation-delay: 100ms;
    }

    &.dot-2 {
      -webkit-animation-delay: 200ms;
      animation-delay: 200ms;
    }

    &.dot-3 {
      -webkit-animation-delay: 300ms;
      animation-delay: 300ms;
    }

    @keyframes jump {
      0% {
        bottom: 0;
      }
      20% {
        bottom: 5px;
      }
      40% {
        bottom: 0;
      }
    }
  `,
);

type ModeButtonProps = { mode: Mode };

export function ModeButton({ mode }: ModeButtonProps) {
  const origin = useRoutesStore((state) => state.routeLocations[RouteLocationType.ORIGIN]);
  const destination = useRoutesStore((state) => state.routeLocations[RouteLocationType.DESTINATION]);
  const selectedMode = useRoutesStore((state) => state.selectedMode);
  const { updateSelectedMode } = useRoutesStore((state) => state.actions);

  const routes = useRoutes();
  const duration = RoutePropertyService.getReadableDuration(routes, mode);

  const alternativeRouteIncluded =
    routes &&
    mode === Mode.CAR &&
    routes[mode].data?.serviceDelivery?.deliveryPayload?.individualRouteResponse?.individualRouteResponseContext
      ?.situations?.roadSituation;

  return (
    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
      <StyledButton
        startIcon={<ModeIcon mode={mode} />}
        variant="contained"
        onClick={() => {
          updateSelectedMode({ mode, index: 0 });
        }}
        $activeButton={mode === selectedMode.mode && true}
        disabled={!origin || !destination || routes[mode].isFetching}
      >
        {routes[mode].isFetching ? (
          <Box sx={{ display: 'flex' }}>
            <StyledBox component="span" className="dot-1" />
            <StyledBox component="span" className="dot-2" />
            <StyledBox component="span" className="dot-3" />
          </Box>
        ) : (
          duration || ''
        )}
        {alternativeRouteIncluded && mode === Mode.CAR && <StyledCircle />}
      </StyledButton>
    </Grid>
  );
}
