import { Typography, useTheme } from '@mui/material';
import { Layer, Popup, Source } from 'react-map-gl';

import { PopupContent } from '@/components/PopupContent';
import { PopupDatePeriod } from '@/components/popup/PopupDatePeriod';
import { PopupTitle } from '@/components/popup/PopupTitle';
import { TrafficReportFeature } from '@/types/TrafficReportFeature';
import { TrafficReportLayerType } from '@/types/TrafficReportLayerType';
import { TrafficReportType } from '@/types/TrafficReportType';

type TrafficReportPopupProps = {
  feature?: TrafficReportFeature;
  onClose: () => void;
};

export function TrafficReportPopup({ feature, onClose }: TrafficReportPopupProps) {
  const theme = useTheme();

  if (!feature) {
    return null;
  }

  const [longitude, latitude] = feature.geometry.coordinates;

  let title;

  const { type } = feature.properties;

  switch (type) {
    case TrafficReportType.CONSTRUCTION_WORKS:
      title = 'Bauarbeiten';
      break;
    case 'PublicEvent':
      title = 'Veranstaltung';
      break;
    case 'Accident':
      title = 'Unfall';
      break;
    case 'AbnormalTraffic':
      title = 'Stau';
      break;
    case 'ReroutingManagement':
      title = 'Umleitung';
      break;
    case 'MaintenanceWorks':
      title = 'Wartungsarbeiten';
      break;
    case 'EnvironmentalObstruction':
      title = 'Naturereignis';
      break;
    case 'Conditions':
      title = 'Umweltalarm';
      break;
    default:
      title = `Sonstiges (${type})`;
  }

  const description = feature.properties.comments?.replace('{}', '').replace('{"', '').replace('"}', '');

  return (
    <>
      <Popup
        key={feature.properties.id}
        longitude={longitude}
        latitude={latitude}
        onClose={onClose}
        offset={18}
        maxWidth="min(480px, 75vw)"
        closeButton={false}
      >
        <PopupContent onClose={onClose}>
          <PopupTitle>{title}</PopupTitle>

          <PopupDatePeriod feature={feature} />

          {description && <Typography>{description}</Typography>}
        </PopupContent>
      </Popup>

      {feature.properties.originalGeometry && (
        <Source type="geojson" data={JSON.parse(feature.properties.originalGeometry)}>
          <Layer
            beforeId={TrafficReportLayerType.TRAFFIC_REPORT}
            type="line"
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
            }}
            paint={{
              'line-color': theme.palette.error.main,
              'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 2, 14, 4, 18, 8],
              'line-dasharray': [1, 2],
            }}
          />
        </Source>
      )}
    </>
  );
}
