import { StrategyLayers } from '@/components/map/StrategyLayers';
import { TrafficInfoBoardLayer } from '@/components/map/TrafficInfoBoardLayer';
import { useMapStore } from '@/stores/useMapStore';

export function TrafficManagementLayers() {
  const { trafficManagement } = useMapStore((state) => state.layersControl);

  return trafficManagement ? (
    <>
      <TrafficInfoBoardLayer />
      <StrategyLayers />
    </>
  ) : null;
}
