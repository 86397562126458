import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { ReactNode, useEffect } from 'react';

import { PageWrapper } from '@/pages/PageWrapper';
import { StyledLink } from '@/pages/StyledLink';
import { StyledPageTitle } from '@/pages/StyledPageTitle';
import { StyledTypography } from '@/pages/StyledTypography';
import { useUserStore } from '@/stores/useUserStore';

type TypographyListItemProps = {
  children: ReactNode;
};

function TypographyListItem({ children }: TypographyListItemProps) {
  return <StyledTypography component="li">{children}</StyledTypography>;
}

type TypographyTitleProps = {
  children: ReactNode;
};

function TypographyTitle({ children }: TypographyTitleProps) {
  return (
    <Typography variant="h2" mt={3}>
      {children}
    </Typography>
  );
}

export function DataPrivacyPage() {
  const mapVisible = useUserStore((state) => state.mapVisible);
  const { setMapVisible } = useUserStore((state) => state.actions);

  useEffect(() => {
    document.title = 'Datenschutz - Vielmobil';
  }, []);

  return (
    <PageWrapper>
      <StyledPageTitle title="Datenschutzerklärung" />
      <TypographyTitle>1. Bezeichnung des Datenverarbeitungsverfahrens</TypographyTitle>
      <StyledTypography>
        Mit dieser Datenschutzerklärung informiert die ivm GmbH als verantwortliche Person nach Art. 4 Nr. 7 DS-GVO Sie
        als betroffene Person (nachfolgend „Nutzer“ genannt) darüber, welche personenbezogenen Daten von Ihnen bei der
        Nutzung des Internetportals „www.vielmobil.info“ (nachfolgend nur „Internetportal“ genannt) verarbeitet werden
        und wie damit verfahren wird.
      </StyledTypography>
      <TypographyTitle>2. Datenschutz </TypographyTitle>
      <StyledTypography>
        a) Das Internetportal wird von der ivm GmbH (Integriertes Verkehrs- und Mobilitätsmanagement Region Frankfurt
        RheinMain), Bessie-Coleman-Straße 7, 60549 Frankfurt am Main betrieben, nachfolgend als „ivm“, „wir“ oder „uns“
        bezeichnet.
      </StyledTypography>
      <StyledTypography>
        Das in unserem Internetportal zur Verfügung gestellte Angebot wird grundsätzlich jeder natürlichen Person und
        jedem Unternehmen, nachfolgend „Nutzer“ genannt, zur Verfügung gestellt.
      </StyledTypography>
      <StyledTypography>
        b) Wir nehmen den Schutz der persönlichen Daten der Nutzer sehr ernst. Wir behandeln die Daten der Nutzer
        vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
      </StyledTypography>
      <StyledTypography>
        c) Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz dieser Daten vor dem Zugriff durch Dritte kann schon
        aus technischen Gründen nicht garantiert werden.
      </StyledTypography>
      <StyledTypography>
        d) Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung
        von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich
        widersprochen. Wir behalten uns zudem ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
        Werbeinformationen, etwa durch Spam-Mails, vor.
      </StyledTypography>
      <TypographyTitle>3. Auftragsdatenverarbeiter</TypographyTitle>
      <StyledTypography>
        Das Internetportal wird im Auftrag der ivm durch die TraffiCon GmbH, Strubergasse 26, A-5020 Salzburg
        betrieben.Entsprechend hat die ivm mit der Trafficon GmbH eine Auftragsverarbeitungsvereinbarung geschlossen.
      </StyledTypography>
      <TypographyTitle>4. SSL-Verschlüsselung</TypographyTitle>
      <StyledTypography>
        a) Unser Internetportal verwendet aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher
        Inhalte, wie zum Beispiel von Anfragen, die Nutzer über das Portal an uns senden, eine SSL-Verschlüsselung. Eine
        verschlüsselte Verbindung erkennt man daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
        wechselt und die Browserzeile ein Vorhängeschloss-Symbol enthält.
      </StyledTypography>
      <StyledTypography>
        b) Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die vom Nutzer an uns übermittelt werden, im
        Regelfall nicht von Dritten mitgelesen werden.
      </StyledTypography>
      <TypographyTitle>5. Umfang und Zweck der Verarbeitung personenbezogener Daten </TypographyTitle>
      <StyledTypography>
        a) Wir speichern Daten über jeden Zugriff eines Nutzers auf das Angebot unseres Internetportals über die
        Server-Logfiles. Des Weiteren erfassen wir auftretende Fehler bei der Nutzung des Internetportals. Zugriffsdaten
        sind:
      </StyledTypography>
      <Typography component="ul">
        <TypographyListItem>Der Name der abgerufenen Webseite</TypographyListItem>
        <TypographyListItem>Datum und Uhrzeit der Serveranfrage</TypographyListItem>
        <TypographyListItem>Die übertragene Datenmenge</TypographyListItem>
        <TypographyListItem>Browsertyp/ Browserversion</TypographyListItem>
        <TypographyListItem>Das vom Nutzer verwendete Betriebssystem</TypographyListItem>
        <TypographyListItem>Die IP-Adresse des Nutzers</TypographyListItem>
      </Typography>
      <StyledTypography>
        b) Diese Protokolldaten werden von uns nur für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit
        und der Optimierung des Angebots auf dem Internetportal verwendet. Wir behalten uns jedoch vor, die
        Protokolldaten nachträglich zu prüfen, wenn wir aufgrund konkreter Anhaltspunkte Kenntnis über eine
        rechtswidrige Nutzung erhalten oder der berechtigte Verdacht einer rechtswidrigen Nutzung besteht.
      </StyledTypography>

      <TypographyTitle>6. Kartenapplikation Mapbox </TypographyTitle>
      <StyledTypography>
        a) Wir haben auf unserem Internetportal den Kartendiensts Mapbox der Mapbox Inc., 740 15th Street NW, 5th Floor,
        Washington, District of Columbia 20005, USA, eingebunden. Das führt dazu, dass nach Zustimmung im
        Willkommensdialog mittels Klick auf „Weiter“ Daten wie Ihre IP-Adresse von Mapbox an Server in die USA
        übertragen und gespeichert werden. Nähere Informationen zur Erhebung und Nutzung der Daten sowie zu Ihren
        Rechten und Möglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte der{' '}
        <StyledLink referrerPolicy="no-referrer" href="https://www.mapbox.com/legal/privacy">
          Website von Mapbox
        </StyledLink>
        <StyledTypography component="legend">
          Meine Zustimmung zur Nutzung von Mapbox ändern:
          <FormGroup sx={{ alignItems: 'flex-start' }}>
            <FormControlLabel
              sx={{ margin: 0 }}
              control={<Switch color="secondary" checked={mapVisible} onChange={() => setMapVisible(!mapVisible)} />}
              label={`Nutzung von Mapbox (${mapVisible ? 'aktiviert' : 'deaktiviert'})`}
            />
          </FormGroup>
        </StyledTypography>
      </StyledTypography>

      <TypographyTitle>Quellenangabe</TypographyTitle>
      <StyledTypography>
        Diese Datenschutzerklärung wurde erstellt mit Hilfe von kostenfreien Angeboten unter www.e-recht24.de,
        www.datenschutzbeauftragter-info.de sowie www.newsletter2go.com nebst Ergänzungen und Änderungen durch den
        Datenschutzbeauftragten der ivm.
      </StyledTypography>
    </PageWrapper>
  );
}
