import { Box, css, styled } from '@mui/material';
import { useState } from 'react';
import Map from 'react-map-gl';

import { ContextMenuMarker } from '@/components/map/ContextMenuMarker';
import { LayerControl } from '@/components/map/LayerControl';
import { LevelOfServiceLegend } from '@/components/map/LevelOfServiceLegend';
import { RouteLayers } from '@/components/map/RouteLayers';
import { RouteLocationMarkers } from '@/components/map/RouteLocationMarkers';
import { TRAFFIC_INFO_BOARD_LAYER } from '@/components/map/TrafficInfoBoardLayer';
import { TrafficManagementLayers } from '@/components/map/TrafficManagementLayers';
import { TrafficReportLayers } from '@/components/map/TrafficReportLayers';
import { StrategyPopup } from '@/components/popup/StrategyPopup';
import { TrafficInfoBoardPopup } from '@/components/popup/TrafficInfoBoardPopup';
import { TrafficReportPopup } from '@/components/popup/TrafficReportPopup';
import { useMapStore } from '@/stores/useMapStore';
import { StrategyFeature } from '@/types/StrategyFeature';
import { StrategyLayerType } from '@/types/StrategyLayerType';
import { TrafficInfoBoardFeature } from '@/types/TrafficInfoBoardFeature';
import { TrafficReportFeature } from '@/types/TrafficReportFeature';
import { TrafficReportLayerType } from '@/types/TrafficReportLayerType';

const MapWrapper = styled(Box)(
  ({ theme }) => css`
    position: relative;
    flex: 1 0 auto;
    height: calc(100vh - ${theme.spacing(8)});

    ${theme.breakpoints.up('md')} {
      height: 100vh;
    }
  `,
);

export function RoutingMap() {
  const viewState = useMapStore((state) => state.viewState);
  const { setViewState } = useMapStore((state) => state.actions);

  const [contextMenu, setContextMenu] = useState<[number, number]>();

  const [trafficReportPopupFeature, setTrafficReportPopupFeature] = useState<TrafficReportFeature>();
  const [strategyPopupFeature, setStrategyPopupFeature] = useState<StrategyFeature>();
  const [trafficInfoBoardPopupFeature, setTrafficInfoBoardPopupFeature] = useState<TrafficInfoBoardFeature>();

  const { trafficReport, trafficManagement } = useMapStore((state) => state.layersControl);

  return (
    <MapWrapper>
      <Map
        {...viewState}
        onMove={(event) => setViewState(event.viewState)}
        mapStyle="mapbox://styles/trafficon/clgzdydjn00hf01qu279062id"
        touchPitch={false}
        pitchWithRotate={false}
        dragRotate={false}
        onContextMenu={(event) => {
          setContextMenu([event.lngLat.lng, event.lngLat.lat]);
        }}
        interactiveLayerIds={[
          ...(trafficReport ? [TrafficReportLayerType.TRAFFIC_REPORT] : []),
          ...(trafficManagement ? [TRAFFIC_INFO_BOARD_LAYER, ...Object.values(StrategyLayerType)] : []),
        ]}
        onClick={(event) => {
          const [feature] = event.features || [];

          if (feature) {
            if (TrafficReportLayerType.TRAFFIC_REPORT === feature.layer.id) {
              setTrafficReportPopupFeature(feature as unknown as TrafficReportFeature);
            } else if (TRAFFIC_INFO_BOARD_LAYER === feature.layer.id) {
              setTrafficInfoBoardPopupFeature(feature as unknown as TrafficInfoBoardFeature);
            } else if (Object.values<string>(StrategyLayerType).includes(feature.layer.id)) {
              setStrategyPopupFeature(feature as unknown as StrategyFeature);
            }
          }
        }}
      >
        <LayerControl />
        <LevelOfServiceLegend />

        <TrafficReportLayers />
        <TrafficManagementLayers />

        <RouteLayers />
        <RouteLocationMarkers />

        <TrafficReportPopup
          feature={trafficReportPopupFeature}
          onClose={() => setTrafficReportPopupFeature(undefined)}
        />
        <StrategyPopup feature={strategyPopupFeature} onClose={() => setStrategyPopupFeature(undefined)} />
        <TrafficInfoBoardPopup
          feature={trafficInfoBoardPopupFeature}
          onClose={() => setTrafficInfoBoardPopupFeature(undefined)}
        />

        <ContextMenuMarker
          longitude={contextMenu && contextMenu[0]}
          latitude={contextMenu && contextMenu[1]}
          onClose={() => setContextMenu(undefined)}
        />
      </Map>
    </MapWrapper>
  );
}
