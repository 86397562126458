import { Trias, LocationRefStructure, IndividualTripParamStructure } from '@/modules/api-routing';
import { LocationFeature } from '@/types/LocationFeature';
import { Mode } from '@/types/Mode';
import { RoutesState } from '@/types/RoutesState';
import { TimeType } from '@/types/TimeType';

export class TriasService {
  public static createRouteRequest({
    origin,
    destination,
    mode,
    time,
  }: {
    origin: LocationFeature;
    destination: LocationFeature;
    mode: Mode;
    time: RoutesState['time'];
  }): Trias {
    const originContextStructure = {
      locationRef: TriasService.getLocationRef(origin),
      ...(time.type === TimeType.DEPARTURE && { depArrTime: time.date.toISOString() }),
    };

    const destinationContextStructure = {
      locationRef: TriasService.getLocationRef(destination),
      ...(time.type === TimeType.ARRIVAL && { depArrTime: time.date.toISOString() }),
    };

    const isIndividualRouteRequest = mode === Mode.WALK || mode === Mode.CYCLE || mode === Mode.CAR;

    return {
      serviceRequest: {
        requestTimestamp: new Date().toISOString(),
        requestorRef: { value: 'vielmobil:webclient' },
        dataVersion: 'TriasExtensionTrafficon:2.7',
        messageIdentifier: { value: new Date().getTime().toString() },

        requestPayload: {
          [isIndividualRouteRequest ? 'individualRouteRequest' : 'tripRequest']: {
            origin: isIndividualRouteRequest ? originContextStructure : [originContextStructure],
            destination: isIndividualRouteRequest ? destinationContextStructure : [destinationContextStructure],

            ...(isIndividualRouteRequest && {
              mode: [
                {
                  mode,
                  maxDistance: { [Mode.WALK]: 10000, [Mode.CYCLE]: 50000, [Mode.CAR]: 1000000 }[mode],
                },
              ],
              params: {
                algorithmType: IndividualTripParamStructure.algorithmType.OPTIMAL,
                banMotorways: false,
                banTollRoads: false,
                banFerries: true,
                allowUnpavedRoads: true,
                includeTrackSections: true,
                includeLegProjection: true,
              },
            }),

            ...(!isIndividualRouteRequest && {
              params: {
                ptModeFilter: {
                  exclude: false,
                  ptMode: [
                    'BUS',
                    'TROLLEY_BUS',
                    'COACH',
                    'RAIL',
                    'INTERCITY_RAIL',
                    'URBAN_RAIL',
                    'METRO',
                    'TRAM',
                    'CABLEWAY',
                    'FUNICULAR',
                  ],
                },
                walkSpeed: 100,
                numberOfResults: 3,
                interchangeLimit: 10,
                itModesToCover: ['WALK'],
                includeTrackSections: true,
                includeLegProjection: true,
                includeTurnDescription: true,
                includeIntermediateStops: true,
                includeFares: true,

                extension: {
                  any: {
                    name: '{http://www.vdv.de/trias/extension/trafficon}ExtendedTripParameters',
                    declaredType: 'eu.trafficon.bo.trias.extension.ExtendedTripParametersStructure',
                    scope: 'javax.xml.bind.JAXBElement$GlobalScope',
                    schemaVersion: 2.7,
                    value: {
                      intermodalTripAlgorithm: {
                        intermodalAlgorithm: ['FASTEST'],
                      },
                      globalIndividualTransportOptions: {
                        mode: 'WALK',
                        maxDistance: 100000,
                      },

                      ...([Mode.BIKE_AND_RIDE, Mode.PARK_AND_RIDE].includes(mode) && {
                        individualTripLegRequest: [
                          {
                            origin: originContextStructure,
                            destination: {
                              locationRef: {
                                pointOfInterestRef: {
                                  value: 'NULL',
                                },
                                locationName: {
                                  text: 'NULL',
                                },
                              },
                            },
                            mode: [
                              {
                                mode: mode === Mode.BIKE_AND_RIDE ? 'CYCLE' : 'SELF_DRIVE_CAR',
                              },
                            ],
                            params: {
                              includeTrackSections: true,
                              includeLegProjection: true,
                              extension: {
                                any: {
                                  name: '{http://www.vdv.de/trias/extension/trafficon}ExtendedTripLegParameters',
                                  declaredType: 'eu.trafficon.bo.trias.extension.ExtendedTripLegParametersStructure',
                                  scope: 'javax.xml.bind.JAXBElement$GlobalScope',
                                  value: {
                                    tripLegRequestUseCaseType: {
                                      value: 'VEHICLE_TO_UNKNOWN_PARK_AND_RIDE',
                                    },
                                    tripLegRequestModeContext: [
                                      {
                                        tripLegIndividualTransportOptions: {
                                          mode: mode === Mode.BIKE_AND_RIDE ? 'CYCLE' : 'SELF_DRIVE_CAR',
                                          maxDistance: mode === Mode.BIKE_AND_RIDE ? 5000 : 100000,
                                          speed: 100,
                                        },
                                        tripLegRequestAlgorithm: {
                                          individualAlgorithm: [mode === Mode.BIKE_AND_RIDE ? 'OPTIMAL' : 'FASTEST'],
                                        },
                                        tripLegIndividualTripParams: {
                                          numberOfResults: 1,
                                          banMotorways: false,
                                          banTollRoads: false,
                                          banFerries: true,
                                          allowUnpavedRoads: true,
                                        },
                                      },
                                    ],
                                  },
                                  nil: false,
                                  globalScope: true,
                                  typeSubstituted: false,
                                },
                              },
                            },
                          },
                        ],
                      }),
                    },
                    nil: false,
                    globalScope: true,
                    typeSubstituted: false,
                  },
                },
              },
            }),
          },
        },
      },
    };
  }

  private static getLocationRef(location: LocationFeature): LocationRefStructure {
    return {
      geoPosition: { longitude: location.geometry.coordinates[0], latitude: location.geometry.coordinates[1] },
      locationName: { text: location.properties.name.full, language: 'de' },
    };
  }

  public static getIndividualRoute(trias?: Trias) {
    return trias?.serviceDelivery?.deliveryPayload?.individualRouteResponse?.routeResult?.[0].route;
  }

  public static getIntermodalRoute(trias?: Trias, index: number = 0) {
    return trias?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult?.[index].trip;
  }

  public static getErrors(trias?: Trias) {
    return (
      trias?.serviceDelivery?.deliveryPayload?.tripResponse?.errorMessage ||
      trias?.serviceDelivery?.deliveryPayload?.individualRouteResponse?.errorMessage
    );
  }
}
