import { Link, IconButton } from '@mui/material';
import { useEffect } from 'react';

import MailIcon from '@/assets/icons/mail-icon.svg?react';
import { PageWrapper } from '@/pages/PageWrapper';
import { StyledLink } from '@/pages/StyledLink';
import { StyledPageTitle } from '@/pages/StyledPageTitle';
import { StyledTypography } from '@/pages/StyledTypography';

export function AboutPage() {
  useEffect(() => {
    document.title = 'Über das Projekt - Vielmobil';
  }, []);

  return (
    <PageWrapper>
      <StyledPageTitle title="Über Vielmobil" />
      <StyledTypography>
        Vielmobil ist ein intermodales Auskunftssystem für die Region Frankfurt RheinMain, das seit 2012 von der ivm
        GmbH betrieben wird. Mehr Infos finden Sie{' '}
        <StyledLink
          target="_blank"
          referrerPolicy="no-referrer"
          href="https://www.ivm-rheinmain.de/intern/projektentwicklung/vielmobil-intermoduales-auskunftsystem/"
        >
          hier
        </StyledLink>
        .
      </StyledTypography>
      <StyledTypography>
        Das Auskunftssystem wurde im Rahmen des mFund geförderten Projektes SCHOOL von der Fa.{' '}
        <StyledLink target="_blank" referrerPolicy="no-referrer" href="https://www.trafficon.eu">
          Trafficon
        </StyledLink>{' '}
        neu entwickelt und befindet sich im Testbetrieb.
      </StyledTypography>
      <StyledTypography>
        Im Forschungsvorhaben{' '}
        <StyledLink
          target="_blank"
          referrerPolicy="no-referrer"
          href="https://www.trafficon.eu/projekte/school-dynamisches-verkehrsmanagement/"
        >
          SCHOOL (Strategiewechsel durch open-data-orientierte Lösungen)
        </StyledLink>{' '}
        werden innovative Werkzeuge entwickelt, um Kommunen bei der Lösung ihrer Verkehrs- und Umweltprobleme zu helfen.
        Dynamisches Verkehrsmanagement soll die negativen Auswirkungen der steigenden Verkehrsmengen vermindern.
      </StyledTypography>
      <StyledTypography>
        Vielmobil wird kontinuierlich weiterentwickelt und neue Technologien erprobt. Im Forschungsvorhaben{' '}
        <StyledLink target="_blank" referrerPolicy="no-referrer" href="https://www.trafficon.eu/projekte/saturn/">
          SATURN (Strategiekonformes multimodales Routing)
        </StyledLink>{' '}
        werden insbesondere kommunale und regionale Verkehrsmanagementstrategien digitalisiert und in Vielmobil
        berücksichtigt. Dabei werden die Anforderungen einer{' '}
        <StyledLink
          target="_blank"
          referrerPolicy="no-referrer"
          href="https://bast.opus.hbz-nrw.de/opus45-bast/frontdoor/deliver/index/docId/2657/file/F-IVS-Reiseinformation_Gesamtversion.pdf"
        >
          IVS-Referenzarchitektur für multimodale Reiseinformationen
        </StyledLink>{' '}
        in der Umsetzung erprobt, die von der Bundesanstalt für Straßenwesen (BASt) definiert wurden.
      </StyledTypography>
      <IconButton
        title="Kontakt"
        component={Link}
        href="mailto:info@ivm-rheinmain.de"
        sx={{ marginTop: (theme) => theme.spacing(3) }}
      >
        <MailIcon />
      </IconButton>
    </PageWrapper>
  );
}
