import { useQueries } from '@tanstack/react-query';

import { Routing } from '@/modules/Routing';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { Mode } from '@/types/Mode';
import { RouteLocationType } from '@/types/RouteLocationType';

export const useRoutes = () => {
  const origin = useRoutesStore((state) => state.routeLocations[RouteLocationType.ORIGIN]);
  const destination = useRoutesStore((state) => state.routeLocations[RouteLocationType.DESTINATION]);
  const time = useRoutesStore((state) => state.time);

  const results = useQueries({
    queries: [Mode.CYCLE, Mode.PUBLIC_TRANSPORT, Mode.CAR, Mode.BIKE_AND_RIDE, Mode.PARK_AND_RIDE].map((mode) => ({
      queryKey: ['routes', mode, { origin, destination, time }],
      queryFn: () => Routing.get({ origin: origin!, destination: destination!, time, mode }),
      enabled: !!origin && !!destination && time.date.isValid(),
      staleTime: Infinity,
    })),
  });

  return {
    [Mode.CYCLE]: results[0],
    [Mode.WALK]: results[0],
    [Mode.PUBLIC_TRANSPORT]: results[1],
    [Mode.CAR]: results[2],
    [Mode.BIKE_AND_RIDE]: results[3],
    [Mode.PARK_AND_RIDE]: results[4],
  };
};
