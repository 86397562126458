import { Box, styled, css } from '@mui/material';

import { Dialog } from '@/components/Dialog';
import { MobileNavigation } from '@/components/MobileNavigation';
import { RoutingMap } from '@/components/map/RoutingMap';
import { RoutingMobile } from '@/components/routing/RoutingMobile';
import { RoutingSidebar } from '@/components/routing/RoutingSidebar';
import { useUserStore } from '@/stores/useUserStore';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;

    flex-direction: column;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      display: flex;
    }
  `,
);

export function RoutingPage() {
  const mapVisible = useUserStore((state) => state.mapVisible);

  return (
    <StyledBox>
      <MobileNavigation />
      <RoutingMobile />
      <RoutingSidebar />

      {mapVisible && <RoutingMap />}

      <Dialog />
    </StyledBox>
  );
}
