import { useQuery } from '@tanstack/react-query';
import { Layer, Source } from 'react-map-gl';

import StrategyIcon from '@/assets/icons/map/strategy.svg?url';
import { useMapImages } from '@/hooks/useMapImages';
import { FeatureCollectionToCenterOfMass } from '@/modules/FeatureCollectionToCenterOfMass';
import { GeoServer } from '@/modules/GeoServer';
import { StrategyLayerType } from '@/types/StrategyLayerType';

const STRATEGY_ICON = 'STRATEGY_ICON';

export function StrategyLayers() {
  useMapImages({
    images: [{ name: STRATEGY_ICON, url: StrategyIcon, width: 36, height: 36 }],
  });

  const { data: alternativeRouting } = useQuery({
    queryKey: [StrategyLayerType.ALTERNATIVE_ROUTING],
    queryFn: () => FeatureCollectionToCenterOfMass.fetch(GeoServer.wfs({ typeName: 'alternativrouten2' })),
  });
  const { data: parkGuidanceCityAvoidance } = useQuery({
    queryKey: [StrategyLayerType.PARK_GUIDANCE_CITY_AVOIDANCE],
    queryFn: () => FeatureCollectionToCenterOfMass.fetch(GeoServer.wfs({ typeName: 'parkleitstrategie2' })),
  });
  const { data: gevas } = useQuery({
    queryKey: [StrategyLayerType.GEVAS],
    queryFn: () => FeatureCollectionToCenterOfMass.fetch(GeoServer.wfs({ typeName: 'strategien_gevas_tm_routes' })),
  });

  return (
    <>
      {[
        { id: StrategyLayerType.ALTERNATIVE_ROUTING, data: alternativeRouting },
        { id: StrategyLayerType.PARK_GUIDANCE_CITY_AVOIDANCE, data: parkGuidanceCityAvoidance },
        { id: StrategyLayerType.GEVAS, data: gevas },
      ].map(({ id, data }) => (
        <Source key={id} id={id} type="geojson" data={data || { type: 'FeatureCollection', features: [] }}>
          <Layer
            id={id}
            type="symbol"
            layout={{
              'icon-allow-overlap': true,
              'icon-size': 0.5,
              'icon-image': STRATEGY_ICON,
            }}
          />
        </Source>
      ))}
    </>
  );
}
