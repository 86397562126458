import { Box, Typography, Grid, styled, css, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { RoutePropertyService } from '@/modules/RoutePropertyService';
import { Duration } from '@/modules/api-routing';

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    height: ${theme.spacing(3)};
    overflow: hidden;
    font-weight: bold;
    margin-bottom: ${theme.spacing(2)};
  `,
);

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    background: ${theme.palette.primary.light};
    padding: ${theme.spacing(1)};
    text-align: left;

    ${theme.breakpoints.up('md')} {
      background: ${theme.palette.primary.main};
    }
  `,
);

type ResultDetailsProps = {
  publicMode?: boolean;
  duration?: Duration;
  time?: string;
  changeMode?: string;
  distance?: number;
  icon?: ReactNode;
  place?: string | undefined;
  changePlace?: string;
  alternativeRouteDescription?: string;
  alternativeRouteDetails?: string;
};

export function ResultDetails({
  publicMode,
  duration,
  time,
  changeMode,
  distance,
  icon,
  place,
  changePlace,
  alternativeRouteDescription,
  alternativeRouteDetails,
}: ResultDetailsProps) {
  const theme = useTheme();

  return (
    <Box sx={{ color: theme.palette.common.white }}>
      {(duration && distance) || (changeMode && changePlace) ? (
        <StyledGrid container>
          <Grid item xs={3}>
            <StyledTypography>{RoutePropertyService.getTimeFromISO(time)}</StyledTypography>
            <Box sx={{ height: theme.spacing(2.5) }} mb={2}>
              {icon}
            </Box>
          </Grid>

          <Grid item xs={9}>
            <StyledTypography>{place}</StyledTypography>

            {publicMode ? (
              <Box>
                <Typography mb={2}>
                  {changeMode} {'>'} {changePlace}
                </Typography>
              </Box>
            ) : (
              <Box>
                <StyledTypography sx={{ fontWeight: 200 }}>
                  {duration && RoutePropertyService.duration(duration)} |{' '}
                  {RoutePropertyService.getReadableDistance(distance)}
                </StyledTypography>
              </Box>
            )}
          </Grid>
        </StyledGrid>
      ) : (
        <StyledGrid container sx={{ display: 'flex' }}>
          <Grid item xs={3}>
            {!icon ? (
              <StyledTypography>{RoutePropertyService.getTimeFromISO(time)}</StyledTypography>
            ) : (
              <Box>{icon}</Box>
            )}
          </Grid>

          <StyledGrid item xs={9} sx={{ paddingLeft: 0, paddingTop: 0 }}>
            <StyledTypography>{place || alternativeRouteDescription}</StyledTypography>
            {alternativeRouteDetails && (
              <StyledTypography sx={{ height: 'fit-content' }}>{alternativeRouteDetails}</StyledTypography>
            )}
          </StyledGrid>
        </StyledGrid>
      )}
    </Box>
  );
}
