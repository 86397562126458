import dayjs from 'dayjs';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { RouteLocationType } from '@/types/RouteLocationType';
import { RoutesState } from '@/types/RoutesState';
import { TimeType } from '@/types/TimeType';

export const useRoutesStore = create<RoutesState>()(
  persist(
    (set, get) => ({
      routeLocations: {
        [RouteLocationType.ORIGIN]: undefined,
        [RouteLocationType.DESTINATION]: undefined,
      },

      time: {
        type: TimeType.DEPARTURE,
        date: dayjs(),
      },

      selectedMode: {
        mode: undefined,
        index: 0,
      },

      actions: {
        updateRouteLocations: ({ routeLocationType, location }) =>
          set(({ routeLocations }) => ({
            routeLocations: {
              ...routeLocations,
              [routeLocationType]: location && {
                ...location,
                properties: { ...location.properties, routeLocationType },
              },
            },
          })),

        updateTime: (updatedValues) =>
          set(({ time }) => ({
            time: {
              ...time,
              ...updatedValues,
            },
          })),

        updateSelectedMode: (selectedMode) =>
          set({
            selectedMode: {
              ...get().selectedMode,
              ...selectedMode,
            },
          }),
      },
    }),
    {
      name: 'vielmobil.info/routes',
      version: 1,

      partialize: ({ actions, time, selectedMode, ...rest }) => rest,
    },
  ),
);
