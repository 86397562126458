import dayjs from 'dayjs';
import { Feature, FeatureCollection, LineString, Point, Geometry } from 'geojson';

import { TriasService } from '@/modules/TriasService';
import { RouteStructure, TripStructure, Duration, GeoPositionStructure } from '@/modules/api-routing';
import { Mode } from '@/types/Mode';
import { UseRoutesResult } from '@/types/UseRoutesResult';

export class RoutePropertyService {
  private static getSegmentsSlice(
    segments: GeoPositionStructure[],
    start: number,
    end: number,
    type: 'route' | 'strategy-preferred',
  ): Feature<LineString, { type: 'route' | 'strategy-preferred' }> {
    return {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: segments
          .slice(start, end)
          .map(({ longitude, latitude }) => [longitude as number, latitude as number]),
      },
      properties: { type },
    };
  }

  public static getReadableDuration(routes?: UseRoutesResult, mode?: Mode) {
    let duration =
      routes &&
      mode &&
      (TriasService.getIndividualRoute(routes[mode].data)?.duration ||
        TriasService.getIntermodalRoute(routes[mode].data)?.duration);

    if (mode === Mode.WALK && duration) {
      const minutes = dayjs.duration(duration).asMinutes() * 3;
      duration = dayjs.duration({ minutes }).toISOString() as Duration;
    }

    return duration && RoutePropertyService.duration(duration);
  }

  public static duration(duration: Duration) {
    const hours = Math.floor(dayjs.duration(duration).asHours());
    const minutes = Math.round(dayjs.duration(duration).asMinutes() % 60);

    let result = '';

    if (hours) {
      result += `${hours} h`;
    }

    if (minutes) {
      result += ` ${minutes} min`;
    }

    return result.trim();
  }

  public static getReadableDistance(distance: number | undefined) {
    if (distance === undefined) return '';
    if (distance < 1000) return `${distance} m`;

    return `${(distance / 1000).toFixed(1).replace('.', ',')} km`;
  }

  public static getTimeFromISO = (timeChange: any) => {
    const newTime = new Date(timeChange);
    const hour = newTime.getHours();
    const minute = newTime.getMinutes();

    return minute >= 10 ? `${hour}:${minute}` : `${hour}:0${minute}`;
  };

  public static getRouteGeoJson(routes: UseRoutesResult, selectedMode: { mode: Mode | undefined; index: number }) {
    if (selectedMode.mode === Mode.CAR) {
      return RoutePropertyService.getCarGeoJson(TriasService.getIndividualRoute(routes[Mode.CAR].data));
    }

    if (
      selectedMode.mode === Mode.PUBLIC_TRANSPORT ||
      selectedMode.mode === Mode.BIKE_AND_RIDE ||
      selectedMode.mode === Mode.PARK_AND_RIDE
    ) {
      return RoutePropertyService.getPublicTransportGeoJson(
        TriasService.getIntermodalRoute(routes[selectedMode.mode].data, selectedMode.index),
        selectedMode.mode,
      );
    }

    return RoutePropertyService.getIndividualGeoJson(
      selectedMode.mode && TriasService.getIndividualRoute(routes[selectedMode.mode].data),
    );
  }

  private static getIndividualGeoJson(route: RouteStructure | undefined) {
    const featureCollection: FeatureCollection<LineString> = {
      type: 'FeatureCollection',
      features: [],
    };

    if (route === undefined) return featureCollection;

    route.routeLeg?.forEach((leg) => {
      const lineString: LineString = {
        type: 'LineString',
        coordinates: [],
      };

      leg.legTrack?.trackSection?.forEach((section) => {
        section.projection?.position?.forEach((pos) => {
          lineString.coordinates.push([pos.longitude as number, pos.latitude as number]);
        });
      });
      const feature: Feature<LineString> = {
        type: 'Feature',
        geometry: lineString,
        properties: {},
      };

      featureCollection.features.push(feature);
    });

    return featureCollection;
  }

  public static getInterchangeGeoJson(
    routes: UseRoutesResult,
    selectedMode: { mode: Mode | undefined; index: number },
  ) {
    const featureCollection: FeatureCollection<Point> = {
      type: 'FeatureCollection',
      features: [],
    };
    const route =
      selectedMode.mode && TriasService.getIntermodalRoute(routes[selectedMode.mode].data, selectedMode.index);
    if (route === undefined) return featureCollection;

    route.tripLeg?.forEach((leg) => {
      if (
        leg.interchangeLeg?.interchangeMode === 'BIKE_AND_RIDE' ||
        leg.interchangeLeg?.interchangeMode === 'PARK_AND_RIDE'
      ) {
        const pointInterchange: Point = {
          type: 'Point',
          coordinates: [],
        };

        const legStartPosition = leg.interchangeLeg?.legStart?.geoPosition;
        pointInterchange.coordinates.push(legStartPosition?.longitude as number, legStartPosition?.latitude as number);
        const interchangeName = leg.interchangeLeg?.legEnd?.locationName?.text;
        const feature: Feature<Point> = {
          type: 'Feature',
          geometry: pointInterchange,
          properties: {
            type: leg.interchangeLeg?.interchangeMode === 'PARK_AND_RIDE' ? 'Park + Ride' : 'Bike + Ride',
            name: interchangeName,
          },
        };

        featureCollection.features.push(feature);
      }
    });

    return featureCollection;
  }

  private static getCarGeoJson(route: RouteStructure | undefined) {
    const featureCollection: FeatureCollection<LineString> = {
      type: 'FeatureCollection',
      features: [],
    };

    if (route === undefined) return featureCollection;

    const alternativeSegments = route?.routeLeg?.[0].legTrack?.trackSection?.[0].extension?.any?.value
      ?.segmentReferences
      ? route?.routeLeg?.[0].legTrack?.trackSection?.[0].extension?.any?.value?.segmentReferences.segmentReference.filter(
          (projectIndex: any) => projectIndex.unspecifiedInformationByKeyValue,
        )
      : [];

    const alternativeSections = (
      alternativeSegments.map(
        (coordinate: { projectionPositionIndexStart: number; projectionPositionIndexStop: number }) => ({
          start: coordinate.projectionPositionIndexStart,
          stop: coordinate.projectionPositionIndexStop,
        }),
      ) as { start: number; stop: number }[]
    )
      .sort(({ start: startA }, { start: startB }) => startA - startB)
      .reduce(
        (sections, section) => {
          if (sections[sections.length - 1] && sections[sections.length - 1].stop === section.start) {
            // eslint-disable-next-line no-param-reassign
            sections[sections.length - 1].stop = section.stop;
          } else {
            sections.push(section);
          }

          return sections;
        },
        [] as { start: number; stop: number }[],
      );

    let currentIndex = 0;
    const segments = route?.routeLeg?.[0].legTrack?.trackSection?.[0].projection?.position;

    if (segments) {
      alternativeSections.forEach((section) => {
        if (currentIndex !== section.start) {
          featureCollection.features.push(
            RoutePropertyService.getSegmentsSlice(segments, currentIndex, section.start + 1, 'route'),
          );
          currentIndex = section.start;
        }

        featureCollection.features.push(
          RoutePropertyService.getSegmentsSlice(segments, section.start, section.stop + 1, 'strategy-preferred'),
        );
        currentIndex = section.stop;
      });

      if (currentIndex !== segments.length) {
        featureCollection.features.push(
          RoutePropertyService.getSegmentsSlice(segments, currentIndex, segments.length, 'route'),
        );
      }
    }

    return featureCollection;
  }

  public static getAlternativeStrategyGeoJson(routeAvoid?: Geometry, route?: Geometry) {
    const featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    if (routeAvoid) {
      featureCollection.features.push({
        type: 'Feature',
        geometry: routeAvoid,
        properties: {
          type: 'strategy-avoid',
        },
      });
    }

    if (route) {
      featureCollection.features.push({
        type: 'Feature',
        geometry: route,
        properties: {
          type: 'strategy-preferred',
        },
      });
    }

    return featureCollection;
  }

  private static getPublicTransportGeoJson(route: TripStructure | undefined, mode: Mode) {
    const featureCollection: FeatureCollection<LineString> = {
      type: 'FeatureCollection',
      features: [],
    };

    if (route === undefined) return featureCollection;

    const lineWalkBeginString: LineString = {
      type: 'LineString',
      coordinates: [],
    };

    const lineInterchangeModeString: LineString = {
      type: 'LineString',
      coordinates: [],
    };

    const lineInterchangeString: LineString = {
      type: 'LineString',
      coordinates: [],
    };

    const lineWalkEndString: LineString = {
      type: 'LineString',
      coordinates: [],
    };

    route.tripLeg?.forEach((leg, index) => {
      const lineString: LineString = {
        type: 'LineString',
        coordinates: [],
      };

      if (leg.continuousLeg?.legTrack) {
        leg.continuousLeg?.legTrack?.trackSection?.forEach((section) => {
          section.projection?.position?.forEach((pos) => {
            lineInterchangeModeString.coordinates.push([pos.longitude as number, pos.latitude as number]);
          });
        });
      }

      const startPosition = leg?.continuousLeg?.legStart?.geoPosition;
      const endPosition = leg?.continuousLeg?.legEnd?.geoPosition;

      if (leg.interchangeLeg) {
        const startInterchange = leg.interchangeLeg?.legStart?.geoPosition;
        const endInterchange = leg.interchangeLeg?.legEnd?.geoPosition;

        if (
          leg.interchangeLeg?.interchangeMode === 'BIKE_AND_RIDE' ||
          leg.interchangeLeg?.interchangeMode === 'PARK_AND_RIDE'
        ) {
          lineInterchangeString.coordinates.push(
            [startInterchange?.longitude as number, startInterchange?.latitude as number],
            [endInterchange?.longitude as number, endInterchange?.latitude as number],
          );
        } else if (leg.interchangeLeg?.interchangeMode === 'WALK') {
          lineString.coordinates.push(
            [startInterchange?.longitude as number, startInterchange?.latitude as number],
            [endInterchange?.longitude as number, endInterchange?.latitude as number],
          );
        }
      }

      if (index === 0) {
        lineWalkBeginString.coordinates.push(
          [startPosition?.longitude as number, startPosition?.latitude as number],
          [endPosition?.longitude as number, endPosition?.latitude as number],
        );
      }
      const tripsLength = route.tripLeg?.length;

      if (tripsLength && index === tripsLength - 1) {
        lineWalkEndString.coordinates.push(
          [startPosition?.longitude as number, startPosition?.latitude as number],
          [endPosition?.longitude as number, endPosition?.latitude as number],
        );
      }

      leg.timedLeg?.legTrack?.trackSection?.forEach((section) => {
        section.projection?.position?.forEach((pos) => {
          lineString.coordinates.push([pos.longitude as number, pos.latitude as number]);
        });
      });

      const featurePublic: Feature<LineString> = {
        type: 'Feature',
        geometry: lineString,
        properties: {
          type: 'pt',
        },
      };

      const featureWalkBegin: Feature<LineString> = {
        type: 'Feature',
        geometry: lineWalkBeginString,
        properties: {},
      };

      const featureInterchangeMode: Feature<LineString> = {
        type: 'Feature',
        geometry: lineInterchangeModeString,
        properties: {},
      };

      const featureInterchange: Feature<LineString> = {
        type: 'Feature',
        geometry: lineInterchangeString,
        properties: {},
      };

      const featureWalkEnd: Feature<LineString> = {
        type: 'Feature',
        geometry: lineWalkEndString,
        properties: {},
      };

      if (mode === Mode.PUBLIC_TRANSPORT) {
        featureCollection.features.push(featureWalkBegin, featurePublic, featureWalkEnd);
      } else {
        featureCollection.features.push(featureInterchangeMode, featureInterchange, featurePublic, featureWalkEnd);
      }
    });

    return featureCollection;
  }
}
