import { styled, css, Stack, Button } from '@mui/material';

import { useMapStore } from '@/stores/useMapStore';
import { transientOptions } from '@/utils/transient-options';

const StyledStack = styled(Stack)(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.spacing(4)};
    left: ${theme.spacing(1)};

    ${theme.breakpoints.up('md')} {
      bottom: auto;
      top: ${theme.spacing(1)};
    }
  `,
);

const StyledButton = styled(
  Button,
  transientOptions,
)<{ $active?: boolean }>(
  ({ theme, $active }) => css`
    background-color: ${$active && theme.palette.common.white};
    color: ${$active ? theme.palette.primary.light : theme.palette.common.white};

    &.MuiButtonBase-root:hover {
      background-color: ${$active && theme.palette.common.white};
    }
  `,
);

export function LayerControl() {
  const { trafficReport, trafficManagement } = useMapStore((state) => state.layersControl);
  const { updateLayersControl } = useMapStore((state) => state.actions);

  return (
    <StyledStack direction="row" spacing={1}>
      <StyledButton
        variant="contained"
        $active={trafficReport}
        onClick={() => {
          updateLayersControl({
            trafficReport: !trafficReport,
          });
        }}
      >
        Verkehr
      </StyledButton>
      <StyledButton
        variant="contained"
        $active={trafficManagement}
        onClick={() => {
          updateLayersControl({
            trafficManagement: !trafficManagement,
          });
        }}
      >
        Verkehrslenkung
      </StyledButton>
    </StyledStack>
  );
}
