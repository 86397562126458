import bbox from '@turf/bbox';
import { useEffect } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';

import BikeAndRideTransitionIcon from '@/assets/icons/map/transitions/bike-and-ride-transition.svg?url';
import ParkAndRideTransitionIcon from '@/assets/icons/map/transitions/park-and-ride-transition.svg?url';
import { useMapImages } from '@/hooks/useMapImages';
import { useRoutes } from '@/hooks/useRoutes';
import { RoutePropertyService } from '@/modules/RoutePropertyService';
import { theme } from '@/setup/theme';
import { useRoutesStore } from '@/stores/useRoutesStore';
import { Mode } from '@/types/Mode';
import { RouteLocationType } from '@/types/RouteLocationType';

const ROUTE = 'ROUTE';
const ROUTE_INTERCHANGES = 'ROUTE_INTERCHANGES';

const BIKE_AND_RIDE_TRANSITION_ICON = 'BIKE_AND_RIDE_TRANSITION_ICON';
const PARK_AND_RIDE_TRANSITION_ICON = 'PARK_AND_RIDE_TRANSITION_ICON';

export function RouteLayers() {
  const selectedMode = useRoutesStore((state) => state.selectedMode);
  const routeLocations = useRoutesStore((state) => state.routeLocations);

  const routes = useRoutes();

  const { current: map } = useMap();

  useEffect(() => {
    const origin = routeLocations[RouteLocationType.ORIGIN];
    const destination = routeLocations[RouteLocationType.DESTINATION];

    if (origin && destination) {
      const bounds = bbox({
        type: 'FeatureCollection',
        features: [origin, destination, ...RoutePropertyService.getRouteGeoJson(routes, selectedMode).features].filter(
          (value) => !!value,
        ),
      });

      map?.fitBounds(bounds as [number, number, number, number], {
        padding: { top: selectedMode.mode !== Mode.PUBLIC_TRANSPORT ? 100 : 180, bottom: 50, left: 50, right: 50 },
        animate: true,
        duration: 300,
      });
    } else {
      [origin, destination].forEach((location) => {
        if (location && !map?.getBounds().contains(location.geometry.coordinates as [number, number])) {
          map?.panTo(location.geometry.coordinates as [number, number]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeLocations, selectedMode]);

  useMapImages({
    images: [
      { name: BIKE_AND_RIDE_TRANSITION_ICON, url: BikeAndRideTransitionIcon, width: 36, height: 36 },
      { name: PARK_AND_RIDE_TRANSITION_ICON, url: ParkAndRideTransitionIcon, width: 36, height: 36 },
    ],
  });

  if (selectedMode?.mode && routes[selectedMode.mode].data?.serviceDelivery?.status) {
    const routeSource = RoutePropertyService.getRouteGeoJson(routes, selectedMode);
    const interchangesSource = RoutePropertyService.getInterchangeGeoJson(routes, selectedMode);

    return (
      <>
        <Source id={ROUTE} type="geojson" data={routeSource}>
          <Layer
            id={ROUTE}
            key={ROUTE}
            type="line"
            paint={{
              'line-color': [
                'case',
                ['==', ['get', 'type'], 'strategy-preferred'],
                theme.palette.success.main,
                '#00aeea',
              ],
              'line-width': 6,
              'line-opacity': 0.8,
              'line-dasharray': ['case', ['==', ['get', 'type'], 'pt'], ['literal', [1, 2]], ['literal', [1, 0]]],
            }}
            layout={{ 'line-cap': 'round' }}
          />
        </Source>

        <Source id={ROUTE_INTERCHANGES} type="geojson" data={interchangesSource}>
          <Layer
            id={ROUTE_INTERCHANGES}
            key={ROUTE_INTERCHANGES}
            type="symbol"
            filter={['!', ['has', 'point_count']]}
            layout={{
              'icon-size': 0.5,
              'icon-image': [
                'match',
                ['get', 'type'],
                'Bike + Ride',
                BIKE_AND_RIDE_TRANSITION_ICON,
                'Park + Ride',
                PARK_AND_RIDE_TRANSITION_ICON,
                '',
              ],
            }}
          />
        </Source>
      </>
    );
  }

  return null;
}
