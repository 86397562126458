import { Typography, styled, css } from '@mui/material';
import { useEffect } from 'react';

import { PageWrapper } from '@/pages/PageWrapper';
import { StyledLink } from '@/pages/StyledLink';
import { StyledPageTitle } from '@/pages/StyledPageTitle';
import { StyledTypography } from '@/pages/StyledTypography';

const StyledTypographySubtitle = styled(Typography)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(3)};
    font-style: italic;
    font-size: 0.8rem;
    font-weight: bold;
  `,
);

export function ImprintPage() {
  useEffect(() => {
    document.title = 'Impressum - Vielmobil';
  }, []);

  return (
    <PageWrapper>
      <StyledPageTitle title="Impressum" />
      <StyledTypography>
        <Typography variant="h2">Angaben gemäß § 5 TMG</Typography>
        ivm GmbH <br />
        Bessie-Coleman-Straße 7 <br />
        60549 Frankfurt am Main <br />
      </StyledTypography>
      <StyledTypography>
        <StyledTypographySubtitle>Vertreten durch: </StyledTypographySubtitle>
        Geschäftsführung <br />
        Frau Dipl.-Ing. Heike Mühlhans <br />
        Frau M.A. Anne Rückschloß <br />
      </StyledTypography>
      <StyledTypography>
        <StyledTypographySubtitle>Kontakt: </StyledTypographySubtitle>
        Herrn Dipl.-Ing. Rüdiger Bernhard <br />
        Telefon: +49 (0) 69 / 660759 0 <br />
        Telefax: +49 (0) 69 / 660759 90 <br />
        E-Mail: info@ivm-rheinmain.de <br />
      </StyledTypography>
      <StyledTypography>
        <StyledTypographySubtitle>Registereintrag: </StyledTypographySubtitle>
        Eintragung im Handelsregister. <br />
        Registergericht: Amtsgericht Frankfurt am Main <br />
        Registernummer: 75042 <br />
      </StyledTypography>
      <StyledTypography>
        <StyledTypographySubtitle> Datenschutzbeauftragter:</StyledTypographySubtitle>
        Datenschutzbeauftragter der ivm GmbH
        <br />
        Bessie-Coleman-Straße 7 <br />
        60549 Frankfurt am Main <br />
      </StyledTypography>
      <StyledTypography>E-Mail: datenschutzbeauftragter@ivm-rheinmain.de</StyledTypography>
      <Typography variant="h2" mt={3}>
        Haftungsausschluss (Disclaimer)
      </Typography>

      <StyledTypographySubtitle>Haftung für Inhalte </StyledTypographySubtitle>
      <StyledTypography sx={{ marginTop: 0 }}>
        Die Inhalte unseres Internetportals wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit
        und Aktualität übernehmen wir jedoch keine Gewähr. Für in das Portal eingebundene Leistungen Dritter (z. B.
        Kartografie) liegt die Verantwortung ausschließlich bei den Dritten.
      </StyledTypography>
      <StyledTypography>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
        Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
        übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
        rechtswidrige Tätigkeit hinweisen.
      </StyledTypography>
      <StyledTypography>
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
        hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
        Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
        umgehend entfernen.{' '}
      </StyledTypography>
      <StyledTypographySubtitle>Haftung für Links </StyledTypographySubtitle>
      <StyledTypography sx={{ marginTop: 0 }}>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
        können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
        stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
        Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
        Verlinkung nicht erkennbar.
      </StyledTypography>
      <StyledTypography>
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
        entfernen.{' '}
      </StyledTypography>
      <StyledTypographySubtitle>Urheberrecht </StyledTypographySubtitle>
      <StyledTypography sx={{ marginTop: 0 }}>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Marken-
        und Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.{' '}
      </StyledTypography>
      <StyledTypography>
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
        beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.{' '}
      </StyledTypography>
      <StyledTypography>
        Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
        Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.{' '}
      </StyledTypography>
      <StyledTypography fontStyle="italic">
        Quellenangabe: Dieses Impressum wurde erstellt mit dem kostenfreien Impressumgenerator unter{' '}
        <StyledLink target="_blank" referrerPolicy="no-referrer" href="https://www.e-recht24.de">
          www.e-recht24.de
        </StyledLink>{' '}
        nebst Ergänzungen und Änderungen durch den Datenschutzbeauftragten der ivm.{' '}
      </StyledTypography>
    </PageWrapper>
  );
}
